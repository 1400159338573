<script setup lang="ts"></script>
<template>
  <slot />
  <div class="header">
    <div class="header-nav">
      <div class="header-logo-container">
        <a href="/"
          ><img
            class="header-logo-image"
            src="@/assets/images/logo2.png"
            alt="Logo"
        /></a>
      </div>
      <div class="header-logo-text">AI Policy Comparator</div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.header {
  height: 70px;
  padding: 0 12px 0 12px;
  border-bottom: 1px solid $background-gradient-2;
  //background-color: $footer-bg-color;
}

.header-nav {
  color: white;
  //background: linear-gradient(135deg, #081229, #0b3954, #4c9fad, #a9d6e5);
  display: flex;
  align-items: center;
  margin: 0;
  // border-width: 0 1px 1px 1px;
  // border-style: solid;
  // border-color: white; //$secondary-color;
  // border-radius: 0 0 16px 16px;
  // box-shadow: $box-shadow-secondary;
}

.header-logo-container {
  width: 100px;
  margin-left: 12px;
}

.header-logo-text {
  margin-top: -8px;
  @include large-normal;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.header-logo-image {
  transform: scaleX(-1);
  height: 70px;
  margin-top: 12px;
  transition: filter 0.4s ease-in-out;
}
.header-logo-image:hover {
  filter: brightness(110%);
}
</style>
